<div class="faspinner" *ngIf="loading">
  <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
</div>




<section [style.direction]="currentLang=='en'?'ltr':'rtl'" *ngIf="!loading" class="apphome">


  <dx-tab-panel>
    <dxi-item title="الاحصائيات">

      <dx-box direction="row" width="100%" [height]="400">

        <dxi-item class="rect demo-light" [ratio]="1"> <dx-chart id="chart" [dataSource]="invoices">
            <dxi-series argumentField="title" valueField="count" name="الفواتير" type="bar" color="#ffaa66">
            </dxi-series>
          </dx-chart> </dxi-item>

      </dx-box>

      <dx-box direction="row" width="100%" [height]="400">
        <dxi-item class="rect demo-dark" [ratio]="1">
          <dx-pie-chart id="pie" title="مبيعات الفروع" palette="bright" [dataSource]="branshinvoice">
            <dxi-series argumentField="title" valueField="count">
              <dxo-label [visible]="true">
                <dxo-connector [visible]="true" [width]="1"></dxo-connector>
              </dxo-label>
            </dxi-series>
            <dxo-size [width]="500"></dxo-size>
            <dxo-export [enabled]="true"></dxo-export>
          </dx-pie-chart>
        </dxi-item>
        <dxi-item class="rect demo-light" [ratio]="1">
          <dx-pie-chart id="pie" title="مشتريات الفروع" palette="bright" [dataSource]="incomebranshinvoice">
            <dxi-series argumentField="title" valueField="count">
              <dxo-label [visible]="true">
                <dxo-connector [visible]="true" [width]="1"></dxo-connector>
              </dxo-label>
            </dxi-series>
            <dxo-size [width]="500"></dxo-size>
            <dxo-export [enabled]="true"></dxo-export>
          </dx-pie-chart>
        </dxi-item>
        <!-- <dxi-item class="rect demo-dark" [ratio]="1"> 
          <dx-pie-chart
          id="pie"
          title="Area of Countries"
          palette="bright"
          [dataSource]="invoices"
  
        >
          <dxi-series argumentField="title" valueField="count">
            <dxo-label [visible]="true">
              <dxo-connector [visible]="true" [width]="1"></dxo-connector>
            </dxo-label>
          </dxi-series>
          <dxo-size [width]="500"></dxo-size>
          <dxo-export [enabled]="true"></dxo-export>
        </dx-pie-chart>
      </dxi-item>  -->
      </dx-box>


    </dxi-item>

    <dxi-item title="الرئيسية">
      <div style="position:relative">
        <div class="setting">
          <h6 class="title">{{'setting'|translate}}</h6>
        </div>
        <div class="flex-all">
          <ng-container *ngFor="let ele of data" class="button">
            <div class="card btn" (click)="getChildren(ele)" [ngClass]="{ 'selected': selectedElement === ele }">
              <i class="fa-solid fa-money-check"></i>
              <!-- <i [class]="options.data.styleClass" [style.rotate]="(currentLang=='en' && options.data.styleClass.includes('fa-caret-left'))?'180deg':'360deg'"></i> -->
              <div *ngIf="currentLang=='ar'">{{ele.nameAr}}</div>
              <div *ngIf="currentLang=='en'">{{ele.nameEn}}</div>
            </div>
          </ng-container>
        </div>
        <hr />
      </div>

      <div class="itreative">
        <ng-container>
          <ng-container *ngFor="let ele of previousparent">
            <div class="flex-all">
              <ng-container *ngFor="let data of ele.children" class="button childrenButton">
                <div class="card btn" (click)="getChildren(data)">
                  <i class="fa-solid fa-money-check"></i>
                  <div *ngIf="currentLang=='ar'">{{data.nameAr}}</div>
                  <div *ngIf="currentLang=='en'">{{data.nameEn}}</div>
                </div>
              </ng-container>
            </div>
            <hr />
            <div class="space"></div>
          </ng-container>
        </ng-container>
      </div>
    </dxi-item>


    <dxi-item title="الاشعارات">


      <div class="itreative">

        <div class="flex-all">
          <ng-container *ngFor="let data of invoices">
            <div class="card " (click)="navigate(data)" [ngClass]="{'red-button': data.count > 0}">

              <i class="fa-solid fa-bell"></i>
              <div *ngIf="currentLang=='ar'">{{data.title}}</div>
              <div *ngIf="currentLang=='en'">{{data.title}}</div>
              <div class="label">{{data.count}}</div>
            </div>
          </ng-container>
        </div>
        <hr />
        <div class="space"></div>

      </div>

      <div class="itreative">

        <div class="flex-all">
          <ng-container *ngFor="let data of storeStatics">
            <div class="card " (click)="navigatestore(data)" [ngClass]="{'red-button': data.count > 0}">

              <i class="fa-solid fa-bell"></i>
              <div *ngIf="currentLang=='ar'">{{data.title}}</div>
              <div *ngIf="currentLang=='en'">{{data.title}}</div>
              <div class="label">{{data.count}}</div>
            </div>
          </ng-container>
        </div>
        <hr />
        <div class="space"></div>

      </div>
    </dxi-item>
    <dxi-item title="البحث عن الاصناف">
      <app-items-search></app-items-search>
      </dxi-item>

  </dx-tab-panel>

</section>