import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';
interface chartobject {
  title: string;
  count: number;

  xternalurl : string;
}


@Component({
  selector: 'app-home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.scss']
})


export class HomeTabComponent implements OnInit {
  selectedElement: any = null;
  sessiondata:any=[]
  data:any;
  previousparent:any=[]
  identifier:number=0
  previous:number=0
  //translate
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  currentLang: any;
  loading:boolean=true


   chartobject : { title: string; count: number }

   invoices: chartobject[] = [];

   branshinvoice : chartobject[] = [];

   incomebranshinvoice : chartobject[] = [];

   storeStatics : chartobject[] = [];
   Notification : [];

  constructor(public gt:genericCustomStoreService,public router:Router) {
    this.currentLang = localStorage.getItem(
      this.LOCALIZATION_LOCAL_STORAGE_KEY
    );
    this.gt.$home.subscribe(res=>{
      this.previousparent=res
    })   






 
  }

 async ngOnInit(){

   this.gt.dropdown('CyclesStatiscs/AllCyclesStatiscsCount').subscribe((res:any) => {


    this.invoices = res;
 
 
    })


 
     this.gt.dropdown('CyclesStatiscs/SalesInvoicesForBranchesCount').subscribe((res:any) => {
 
 
     this.branshinvoice = res;
    
  
     })

     this.gt.dropdown('CyclesStatiscs/StoresCyclesStatiscsCountQuery').subscribe((res:any) => {
 
 
      this.storeStatics = res;
     
   
      })
 
      this.gt.dropdown('CyclesStatiscs/PurchasesInvoicesForBranchesCount').subscribe((res:any) => {
 
 
        this.incomebranshinvoice = res;
  
    
       })
   



    this.sessiondata=await sessionStorage.getItem("data");
    this.data=JSON.parse(this.sessiondata);
    this.data=this.data?.navihationMenu                
    //make parent and child from data
    if(this.data){
      this.data.forEach((ele:any)=>{
        ele.children=[]
        this.data.filter((filterele:any)=>{
          ele.id==filterele.parentId?ele.children.push(filterele):''
        })
        })
        //return basic list of data
        this.data= this.data.filter((res:{children:[],parentId:number})=>{
          return res.children.length>0 && res.parentId!=null
        })
        
        //filter data 
         this.data.forEach((ele:any)=>{
          this.data=this.data.filter((res:any)=>{
            return !ele.children.includes(res)
          })
        })
        this.loading=false
    }
  }

  navigate(data:chartobject){
    console.log(data.xternalurl)
    const url ="Documentary-cycle/" + data.xternalurl
    console.log(url)
    this.router.navigate([`${url}`])

  }
  
  navigatestore(data:chartobject){

  this.router.navigate([`${data.xternalurl}`])

  }
  getChildren(ele:{children:[],externalUrl:string,id:number,parentId:number}){ 
    this.selectedElement = ele;    
    let Find:boolean=false
    let newparent=ele
      for (let index = 0; index < this.previousparent.length; index++) {
        if(ele.children.length>0 && newparent.parentId==this.previousparent[index].parentId){
          Find=true
          this.previous=index
          break
          }  
        }
        if(Find){
          this.previousparent.splice(this.previous,1, ele);
          this.previousparent.length=this.previous+1   
        }

        else if(ele.children.length<=0){
            this.router.navigate([`${ele.externalUrl}`])
            this.gt.$HOMETAB.next(true)
          }

          else{
            this.previousparent.push(ele)
          }

          this.gt.$home.next(this.previousparent)
    } 
    
}
