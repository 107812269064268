<div class="faspinner" *ngIf="loading">
    <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
</div>
<div *ngIf="!loading">
    <div id="form-container" style="width: 100%;">
        <form (submit)="onFormSubmit($event)">
            <dx-form [formData]="ItemSForm" [showColonAfterLabel]="true" [showValidationSummary]="true" #formRef
                style="width: 100%; padding: 1rem 2rem; margin: 2rem 0rem;"
                [rtlEnabled]="currentLang == 'ar' ? true : false">

                <dxo-col-count-by-screen [xs]="1" [sm]="3" [md]="3" [lg]="3"></dxo-col-count-by-screen>

                <dxi-item itemType="group" caption="{{'Store.items.basicinfo' | translate}}" [colCount]="3"
                    [colSpan]="3">

                <dxi-item dataField="parentId" editorType="dxLookup" [colSpan]="1"
                [editorOptions]="{dataSource: itemList, displayExpr: 'nameAr', valueExpr: 'id', onValueChanged :onValueItemsChanged,searchEnabled: true, showClearButton: true}">
                <dxi-validation-rule type="required"
                    message="{{'errors.namerequired' | translate}}"></dxi-validation-rule>
                <dxo-label template="{{'Store.ItemGroup'|translate}}"></dxo-label>
            </dxi-item>

                    <dxi-item dataField="branchId" editorType="dxLookup" [colSpan]="1"
                        [editorOptions]="{dataSource: manufactureCompayList, displayExpr: 'nameAr', onValueChanged :onValueMaufacturChanged,valueExpr: 'id', searchEnabled: true, showClearButton: true}">
                        <dxi-validation-rule type="required"
                            message="{{'errors.namerequired' | translate}}"></dxi-validation-rule>
                        <dxo-label template="{{'Store.ManufacturerCompany' | translate}}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="branchId" editorType="dxLookup" [colSpan]="1"
                        [editorOptions]="{dataSource: mainColorList, displayExpr: 'nameAr', valueExpr: 'id', onValueChanged :onValueMainColorListChanged, searchEnabled: true, showClearButton: true}">
                        <dxi-validation-rule type="required"
                            message="{{'errors.namerequired' | translate}}"></dxi-validation-rule>
                        <dxo-label template="{{'Store.MainDataColors' | translate}}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="branchId" editorType="dxLookup" [colSpan]="1"
                        [editorOptions]="{dataSource: MainDataSizingsList, displayExpr: 'nameAr', valueExpr: 'id', onValueChanged :onValueMainSizeChanged,searchEnabled: true, showClearButton: true}">
                        <dxi-validation-rule type="required"
                            message="{{'errors.namerequired' | translate}}"></dxi-validation-rule>
                        <dxo-label template="{{'Store.items.items' | translate}}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="branchId" editorType="dxLookup" [colSpan]="1"
                        [editorOptions]="{dataSource: MainDataScreeningsList, displayExpr: 'nameAr', valueExpr: 'id',onValueChanged :onValueDataScreeningChanged, searchEnabled: true, showClearButton: true}">
                        <dxi-validation-rule type="required"
                            message="{{'errors.namerequired' | translate}}"></dxi-validation-rule>
                        <dxo-label template="{{'Store.items.itemtype' | translate}}"></dxo-label>
                    </dxi-item>

               
                </dxi-item>
                <dxi-item itemType="button" [buttonOptions]="buttonOptions"></dxi-item>
            </dx-form>
        </form>
    </div>



    <div id="data-grid-demo">
        <div class="publicsetting" style="padding: 0rem 3rem;">
            <h2 class="content-block title animate__animated animate__fadeIn">
                {{ 'publicsettingnav.SearchedData' | translate }}
            </h2>
            <dx-data-grid class="dx-card wide-card" [dataSource]="SearchedItemsData" [focusedRowEnabled]="true"
                [focusedRowIndex]="0" [allowColumnResizing]="true" [columnAutoWidth]="true" [columnHidingEnabled]="true"
                [showColumnLines]="false" [rowAlternationEnabled]="true" [showRowLines]="false" [showBorders]="true"
                [errorRowEnabled]="false" [rtlEnabled]="currentLang == 'ar' ? true : false"
                (onInitNewRow)="onInitNewRow($event)">

                <dxo-editing mode="full" [useIcons]="false" [allowAdding]="false" [allowDeleting]="true"
                    [allowUpdating]="false" [texts]="button">
                </dxo-editing>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [visible]="true" allowedPageSizes="true" displayMode="full" [showPageSizeSelector]="true"
                    [showInfo]="false" [showNavigationButtons]="true">
                </dxo-pager>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxi-column dataField="itemId" caption="{{'publicsettingnav.id' |translate}}" [visible]="false">
                </dxi-column>

                <dxi-column dataField="codeAr" caption="{{'publicsettingnav.codeAr' |translate}}">
                </dxi-column>
                <dxi-column dataField="codeEn" caption="{{'publicsettingnav.codeEn' |translate}}">
                </dxi-column>
                <dxi-column dataField="descriptionAr" caption="{{'publicsettingnav.descriptionAr' |translate}}">
                </dxi-column>
                <dxi-column dataField="descriptionEn" caption="{{'publicsettingnav.descriptionEn' |translate}}">
                </dxi-column>
                <dxi-column dataField="stores.storeId" caption="{{'publicsettingnav.storeId' |translate}}">
                </dxi-column>
                <dxi-column dataField="stores.expireDateList" caption="{{'publicsettingnav.expireDateList' |translate}}">
                </dxi-column>
                <dxi-column dataField="stores.partNumberList" caption="{{'publicsettingnav.partNumberList' |translate}}">
                </dxi-column>
                <dxi-column dataField="stores.volumnsList" caption="{{'publicsettingnav.volumnsList' |translate}}">
                </dxi-column>
                <dxi-column dataField="stores.quantity" caption="{{'publicsettingnav.quantity' |translate}}">
                </dxi-column>
                <dxi-column dataField="stores.gard" caption="{{'publicsettingnav.gard' |translate}}">
                </dxi-column>
          
                <dxi-column type="buttons">
                    <dxi-button name="delete" text="{{'tableinfo.removebutton'|translate}}"></dxi-button>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>